/* 
Keyframe transitions
Arrow bounce
*/

.carousel {
    font-family: 'Kumbh Sans', sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    width: 50%;
    color: white;
}

.arrow-left,
.arrow-right {
    margin: 35px;
    color: rgb(142, 142, 142);
}

.arrow-left {
    animation: left 1s alternate infinite;
}

.arrow-right {
    animation: right 1s alternate infinite;
}

.arrow-left :hover {
    color: white;
}

.arrow-right :hover {
    color: white;
}

@keyframes right {
    0% {
        transform: translate(0, 0);
    }
      
    100% {
        transform: translate(10px, 0);
    }
}

@keyframes left {
    0% {
        transform: translate(0, 0);
    }
      
    100% {
        transform: translate(-10px, 0);
    }
}

.carousel-background {
    display: flex;
    justify-content: center;
    background: url(../assets/images/image.png) top center no-repeat; 
    background-size: cover;
    height: 700px;
    width: 700px;
    border-radius: 50%;
}

.carousel-content {
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center;
    text-align: center;
    height: 650px;
    width: 650px;
    background-color: rgba(255, 255, 255, .3);
    border-radius: 50%;
    align-items: center;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, .4);
}

.carousel-img {
    width: 475px;
    height: auto;
    border: 1px solid black;
    border-radius: 5px;
    box-shadow: 2px 2px 3px 2px rgba(0, 0, 0, .5);
    margin-top: 5%;
    margin-bottom: 5%;
}

.card-name {
    font-weight: 900;
    text-shadow: -1px 2px 1px #000;
}

.card-caption {
    font-weight: 600;
    padding-left: 10%;
    padding-right: 10%;
    text-shadow: -1px 1px 2px #000;
}

.card-url {
    color: white;
    text-decoration: underline;
}

.card-url:hover {
    color: rgba(241, 56, 232);
}

.show {
    display: block;
}

.hide {
    display: none;
}