body {
    background-color: black;
    color: white;
}

.main-sub {
    display: flex;
    align-items: center;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
}