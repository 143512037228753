.resume {
    display: flex;
}

.resume a {
    color: rgb(142, 142, 142);
    margin: 25px;
}

.resume a:hover {
    color: white;
}

.resume h3 {
    margin: 0 auto;
    align-self: flex-end;
}

.resume_img {
    margin: 0 auto;
    max-height: 75%;
    max-width: 60%;
    padding-top: 10%;
    padding-bottom: 10%;
}